















import { Component, Vue } from "vue-property-decorator";
import RctService from "../services/RtcService";

@Component({})
export default class Home extends Vue {
  private service: RctService = new RctService();
  private showData = false;
  private dataLabel = "";
  private data = "";
  private dataButtonText = "Add Client";
  private state = "";
  private messages: { id: number; source: string; message: string }[] = [];
  private currentId = 0;

  private async onDataButtonClick() {
    if (this.state == "") {
      this.service.onOffer = this.showOffer;
      this.service.onOpenDataChannel = () => {
        this.state = "Open";
        this.showData = false;
      };
      this.service.onCloseDataChannel = () => {
        this.state = "Closed";
      };
      this.service.onErrorDataChannel = (error: DOMException) => {
        this.state = "error: " + error.message;
      };
      this.service.onMessage = (message: string) => {
        this.currentId++;
        this.messages.push({
          id: this.currentId,
          source: "them",
          message: message,
        });
      };
      await this.service.startClient(this.data);
      this.data = "";
      this.dataLabel = "Message";
      this.dataButtonText = "Send";
      this.state = "Connecting to Server";
    } else {
      this.currentId++;
      this.messages.push({
        id: this.currentId,
        source: "them",
        message: this.data,
      });
      this.service.sendMessage(this.data);
      this.data = "";
    }
  }

  private showOffer(offer: string) {
    this.showData = true;
    this.dataLabel = "Offer";
    this.data = offer;
  }
}
